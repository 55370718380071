@import "../../variables.scss";

.headerContainer {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
}

.table {
  color: white;
}

.deleteIcon {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 999;

  &:hover {
    background-color: transparent;
  }
}
.nameTableCell {
  color: white;
}
.title {
  color: $font-color-base;
  text-align: right;
  font-weight: 700;
  text-transform: uppercase;
}

.files {
  &Wrapper {
    margin-top: 20px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid $border-color;
    border-radius: $border-radius-lg;
    padding: 20px;
    margin-bottom: 20px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.modal {
  overflow-y: hidden;
  @media (min-width: $desktop) {
    margin: 40px 10px;
  }

  &Head {
    display: flex;
    align-items: start;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 20px;
    @media (max-width: 991px) {
      flex-direction: column;
      width: 100%;
      > * {
        width: 100%;
      }
    }
  }

  &Actions {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &Title {
    color: $font-color-base;
    font-weight: 700;
    text-transform: uppercase;
  }

  &Footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    margin-top: 10px;
  }
}

.custom_body {
  max-height: 450px;
  @media (max-width: 991px) {
    max-height: 400px;
  }
  overflow-y: scroll;
}

.dropzone {
  max-width: 100%;
  &Uploaded {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
  }
  &FileName {
    word-break: break-all;
    padding-right: 10px;
    color: $font-color-base;
  }
}

// file items

.fileHandler {
  display: flex;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
  gap: 10px;
}

.file {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  gap: 5px;
  &Image {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 5px;
    background: rgba(66, 72, 88, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
}

//Editor

.studentEditor {
  display: flex;
  gap: 5px;
  padding-bottom: 5px;
  align-items: start;
  @media (max-width: 991px) {
    flex-direction: column;
    width: 100%;
    > * {
      width: 100%;
    }
  }
}
