@import "../../variables.scss";

.table {
  color: white;
}

.tableActions {
  display: flex;
  gap: 20px;
}

.nameTableCell {
  width: 300px;
}

.modal {
  padding: 50px 10px;
  max-width: 440px;

  &Header {
    text-transform: uppercase;
    color: $font-color-base;
    font-weight: 700;
    line-height: 19px;
    text-align: right;
    margin-bottom: 20px;
  }
}
