@import "../../variables.scss";

.wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  gap: 20px;

  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.stats {
  width: 100%;
  background-color: rgba(42, 43, 48, 0.4);
  border-radius: $border-radius-lg;
  backdrop-filter: blur(150px);
  padding: 50px 10px;
  background-image: url("../../assets/images/abstract.svg");
  background-repeat: no-repeat;

  @media (min-width: $desktop) {
    width: 444px;
    padding: 60px 20px;
  }
}

.title {
  text-align: right;
  color: $font-color-base;
  font-weight: 700;
  line-height: 19px;
  text-transform: uppercase;
}

.diagram {
  margin-top: 40px;
  border-radius: $border-radius-lg;
  border: 1px solid $border-color;
  background: transparent;
  padding: 30px 10px;

  @media (min-width: $desktop) {
    padding: 32px 20px;
  }

  &NoData {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
  }

  &Head {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;

    &Item {
      display: flex;
      align-items: center;
      gap: 5px;
      color: $font-color-base;
    }
  }

  &Inner {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    justify-content: center;
    padding: 15px;
    gap: 15px;
    max-width: 150px;

    @media (min-width: $desktop) {
      max-width: 100%;
    }

    &Item {
      width: 100%;
      display: flex;
      gap: 4px;
      align-items: center;
      color: $font-color-base;
      justify-content: center;
    }
  }
}

.label {
  color: $font-color-secondary;
}

.plans {
  width: 100%;
  padding: 50px 10px;
  border-radius: $border-radius-lg;
  background: url("../../assets/images/abstract_plans.svg"), $primary;
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: cover;

  @media (min-width: $desktop) {
    width: 444px;
    padding: 60px 20px;
  }

  &List {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;
    margin-top: 40px;
  }

  &Item {
    border: 1px solid $font-color-base;
    border-radius: $border-radius-lg;
    padding: 20px 28px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Number {
    width: 40px;
    height: 40px;
    border-radius: $border-radius-full;
    background: $warning;
    filter: drop-shadow(0px 0px 8px rgba(255, 207, 85, 0.8));
    display: flex;
    justify-content: center;
    align-items: center;
    color: $font-color-base;
    font-weight: 700;
  }

  &Name {
    color: $font-color-base;
    font-weight: 700;
    text-transform: uppercase;
  }
}

.calendar {
  width: 100%;
  background: rgba(42, 43, 48, 0.4);
  padding: 50px 10px 100px;
  border-radius: $border-radius-lg;
  backdrop-filter: blur(150px);
  background-image: url("../../assets/images/abstract_calendar.svg");
  background-repeat: no-repeat;
  background-position: right 160%;

  @media (min-width: $desktop) {
    width: 444px;
    padding: 60px 20px;
    background-position: right bottom;
  }

  &Inner {
    border: 1px solid $border-color;
    padding: 10px;
    border-radius: $border-radius-lg;
    display: flex;
    justify-content: center;

    @media (min-width: $desktop) {
      padding: 20px;
    }
  }

  &Today {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px 1px rgba(32, 100, 203, 0.8);
    background-color: $primary;
  }
}
