@import "../../variables.scss";

.classes {
  &Btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-top: 30px;
  }

  &Title {
    text-transform: uppercase;
    font-weight: 700;
    color: $font-color-base;
  }

  &Manage {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;

    @media (min-width: $desktop) {
      margin-top: 30px;
    }

    button {
      border: none;
      padding-right: 0;
      padding-left: 0;

      &:hover {
        background: transparent;
      }
    }
  }
}

.navigation {
  gap: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.managing {
  display: flex;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (min-width: $desktop) {
    flex-direction: row;
  }
}

.modal {
  padding: 50px 10px;

  &Header {
    text-transform: uppercase;
    color: $font-color-base;
    font-weight: 700;
    line-height: 19px;
    text-align: right;
    margin-bottom: 20px;
  }
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &Btns {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.dates {
  color: $font-color-base;
  margin-top: 20px;
  display: flex;
  flex-direction: column-reverse;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 10px;

  @media (min-width: $desktop) {
    flex-direction: row;
  }

  &Time {
    display: flex;
    align-items: center;
    gap: 20px;

    &Part {
      display: flex;
      align-items: center;
      gap: 4px;

      & span {
        font-size: $font-size-sm;
        color: $font-color-secondary;
      }
    }
  }

  &Date {
    display: flex;
    align-items: center;
    gap: 4px;

    & span {
      font-size: $font-size-sm;
      color: $font-color-secondary;
    }
  }
}
