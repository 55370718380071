@import "../../variables.scss";

.table {
  margin-top: 40px;
  color: $font-color-base;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.wrapper {
  display: flex;
  justify-content: space-between;
}

.dates {
  // max-width: 245px;
  // overflow-x: scroll;
  width: 100%;
  overflow-x: auto;

  // @media (min-width: $desktop) {
  //   overflow-x: visible;
  // }

  &Head {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 20px;
    padding: 0 10px;

    @media (min-width: $desktop) {
    }

    &Item {
      display: inline-block;
      flex-direction: column;
      gap: 4px;
      min-width: 180px;
      text-align: right;

      &Day {
        font-weight: 700;
        color: $font-color-base;
        text-transform: uppercase;
      }

      &Date {
        font-size: 10px;
        color: $font-color-secondary;
      }
    }
  }
}

.body {
  background-color: rgba(42, 43, 48, 0.4);
  border-radius: $border-radius-sm;
  padding: 10px;
  margin-bottom: 10px;

  &Day {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }

  &Item {
    width: 170px;
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 4px;
    cursor: pointer;

    @media (min-width: $desktop) {
      width: 170px;
    }

    &Inner {
      width: 100%;
      min-height: 40px;

      & > div {
        margin-bottom: 10px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    &Section {
      display: flex;
      gap: 6px;
      align-items: center;

      &Inner {
        width: 137px;
        padding: 6px;
        border-radius: 15px;
        border: 1px solid rgba(76, 81, 97, 0.8);
        display: flex;
        flex-direction: column;
        gap: 4px;
      }

      &Info {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
        font-size: 8px;
        color: $font-color-secondary;
      }

      &Name {
        font-size: 10px;
        color: $font-color-base;
      }

      &ClsCount {
        display: flex;
        gap: 4px;
      }

      &Location {
        display: flex;
        gap: 4px;
      }

      &Time {
        font-size: 8px;
        color: $font-color-secondary;
        display: flex;
        flex-direction: column;
        gap: 4px;
      }
    }
  }
}

.popover {
  width: 180px;
  text-align: center;

  &Btn {
    text-align: center;
    color: $font-color-base;
  }
}

.teachersName {
  padding-left: 15px;
}

.form {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &Btns {
    margin-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
}

.modal {
  padding: 50px 10px;

  &Datepicker {
    margin-top: 10px;
  }

  &Actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Header {
    text-transform: uppercase;
    color: $font-color-base;
    font-weight: 700;
    line-height: 19px;
    text-align: right;
    margin-bottom: 20px;
  }

  &Dates {
    color: $font-color-base;
    margin-top: 20px;
    display: flex;
    flex-direction: column-reverse;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 10px;

    @media (min-width: $desktop) {
      flex-direction: row;
    }

    &Time {
      display: flex;
      align-items: center;
      gap: 20px;

      &Part {
        display: flex;
        align-items: center;
        gap: 4px;

        & span {
          font-size: $font-size-sm;
          color: $font-color-secondary;
        }
      }
    }

    &Date {
      display: flex;
      align-items: center;
      gap: 4px;

      & span {
        font-size: $font-size-sm;
        color: $font-color-secondary;
      }
    }
  }
}
