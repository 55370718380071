// VARIABLES

// Colors

$primary: #2064cb;
$bg-main: #1f2025;
$bg-secondary: rgba(42, 43, 48, 0.7);
$bg-popup: #2a2b30;
$btn-secondary: rgba(66, 72, 88, 0.5);
$font-color-base: #fff;
$font-color-menu: #1f2025;
$font-color-secondary: #a2a2a8;
$font-color-error: rgb(224, 87, 99);
$border-color: #464752;
$warning: #ffcf55;
$success: #61bd73;

// Border Radius

$border-radius-sm: 30px;
$border-radius-lg: 40px;
$border-radius-full: 100%;

// Font Size

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 18px;
$font-size-xl: 20px;
$font-size-2xl: 24px;
$font-size-3xl: 28px;

// Breakpoints

$desktop: 992px;

// VARIABLES END
