@import "../../../variables.scss";

.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.class {
  border: 1px solid $border-color;
  border-radius: $border-radius-lg;
  padding: 20px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  font-size: $font-size-sm;

  &:hover,
  &Selected {
    box-shadow: 0px 0px 8px 1px rgba(32, 100, 203, 0.8);
    border: 1px solid $primary;
  }

  &Value {
    display: flex;
    align-items: center;
    gap: 4px;
    color: $font-color-secondary;
  }

  &Title {
    text-align: right;
    margin-bottom: 8px;
  }

  &Bottom {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.time {
  color: $font-color-secondary;
  text-align: right;
  font-size: $font-size-sm;
  &From {
    margin-bottom: 8px;
  }
}

.modal {
  padding: 50px 10px;

  &Datepicker {
    margin-top: 10px;
  }

  &Actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
