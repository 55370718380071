@import "../../variables.scss";

.avatar {
  width: 120px;
  height: 120px;
  border-radius: 60px;
  background-color: $primary;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &Wrapper {
    position: relative;
    display: flex;
    justify-content: flex-end;
  }
}

.overlay {
  position: absolute;
  top: 0;
  right: 0;
  width: 120px;
  height: 120px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &ChangeButton {
    margin-bottom: 5px;
    &:hover {
      color: $font-color-secondary;
    }
  }

  &DeleteButton:hover {
    color: $font-color-error;
  }
}

.overlayContent {
  text-align: center;
  color: white;
}
