@import "../../../variables.scss";

.headerWrapper {
  display: flex;
  align-items: center;
  gap: 20px;
}

.headerProfile {
  border-radius: $border-radius-lg;
  border: 1px solid $border-color;
  padding: 16px 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.headerNotify {
  position: relative;
}

.headerNotifyLabel {
  background-color: $primary;
  font-size: $font-size-xxs;
  color: $font-color-base;
  position: absolute;
  bottom: -10px;
  right: -10px;
  padding: 0 5px;
}
