@import "../../variables.scss";

.calendar {
  &Inner {
    border: 1px solid $border-color;
    padding: 10px;
    border-radius: $border-radius-lg;
    display: flex;
    justify-content: center;

    @media (min-width: $desktop) {
      padding: 20px;
    }
  }

  &Today {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 8px 1px rgba(32, 100, 203, 0.8);
    background-color: $primary;
  }
}
