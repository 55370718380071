@import "../../../variables.scss";

.title {
  color: $font-color-base;
  text-align: right;
  font-weight: 700;
  text-transform: uppercase;
}

.notes {
  width: 100%;
  &Wrapper {
    margin-top: 20px;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid $border-color;
    border-radius: $border-radius-lg;
    padding: 20px;
    margin-bottom: 20px;
  }

  &Item {
    padding-bottom: 10px;
    border-bottom: 1px solid $border-color;
    text-align: right;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
      padding-top: 10px;
    }

    &:last-child {
      border-bottom: none;
      padding-bottom: 0;
    }

    &Time {
      margin-top: 5px;
      font-size: $font-size-xs;
      color: $font-color-secondary;
    }
  }
}

.modal {
  padding: 50px 0;
  @media (min-width: $desktop) {
    padding: 60px 20px;
  }

  &Datepicker {
    margin-top: 10px;
  }

  &Actions {
    width: 100%;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &Head {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;
    margin-bottom: 20px;
  }

  &Actions {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &Title {
    color: $font-color-base;
    font-weight: 700;
    text-transform: uppercase;
  }
}
