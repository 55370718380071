@import "../../../variables.scss";

.teacher {
  background-color: $bg-secondary;
  border: 1px solid $border-color;
  padding: 15px 22px;
  backdrop-filter: blur(150px);
  border-radius: $border-radius-lg;
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    border: 1px solid $btn-secondary;
  }

  &Name {
    font-size: $font-size-md;
    color: $font-color-base;
    line-height: 20px;
  }

  &Classes {
    color: $font-color-secondary;
    font-size: $font-size-sm;
    line-height: 20px;

    &Wrap {
      display: flex;
      gap: 12px;
      align-items: center;
      justify-content: flex-end;
      margin-top: 10px;
    }
  }
}

.listIcon {
  max-width: 24px;
}
